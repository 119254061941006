import { TCString } from "@iabtcf/core";
import { getCookie } from "../utils";
import { createTcfModel } from ".";
/**
 * Get the current TCF string stored in our first party cookie and return a valid GVL instance and TCModel.
 * It returns `undefined` if no TCF string is currently present.
 */

function createTcfModelFromCookie(tcf, tcfMetadata, cookieName) {
  var cookieValue = getCookie(cookieName);

  if (!cookieValue) {
    return undefined;
  }

  var _createTcfModel = createTcfModel({
    tcf: tcf,
    tcfMeta: tcfMetadata,
    tcfString: cookieValue
  }),
      gvl = _createTcfModel.gvl,
      model = _createTcfModel.model;

  return {
    gvl: gvl,
    model: model,
    tcfString: cookieValue,
    tcfStringForVendors: TCString.encode(model, {
      isForVendors: true
    })
  };
}

export { createTcfModelFromCookie };