import { scaleMobile } from "../../../..";

function bannerInlineStyleTcfStacksAccordionArrow(_ref) {
  var _ref$bodyDesign = _ref.bodyDesign,
      tcfStacksTitleFontSize = _ref$bodyDesign.tcfStacksTitleFontSize,
      tcfStacksArrowColor = _ref$bodyDesign.tcfStacksArrowColor,
      antiAdBlocker = _ref.customCss.antiAdBlocker,
      mobile = _ref.mobile,
      pageRequestUuid4 = _ref.pageRequestUuid4;
  var useFontSize = scaleMobile(mobile, pageRequestUuid4, +tcfStacksTitleFontSize);
  var style = {
    width: useFontSize,
    height: useFontSize,
    flex: "0 0 ".concat(useFontSize, "px"),
    lineHeight: "".concat(useFontSize, "px"),
    float: "left",
    marginRight: 10,
    color: tcfStacksArrowColor
  };
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-tcf-stacks-arrow",
    style: style
  };
}

export { bannerInlineStyleTcfStacksAccordionArrow };