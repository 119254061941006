import { useMemo } from "react";
import { BannerTcfVendor } from ".";
import { useBanner, bannerGroupsInlineStyleGroup, BannerGroupSectionTitle } from "../../../..";

var BannerTcfVendorList = function BannerTcfVendorList() {
  var banner = useBanner();
  var _banner$tcf = banner.tcf,
      gvlVendors = _banner$tcf.gvl.vendors,
      model = _banner$tcf.model,
      tcfFilterBy = banner.tcfFilterBy,
      _banner$i18n$tcf = banner.i18n.tcf,
      vendors = _banner$i18n$tcf.vendors,
      filterNoVendors = _banner$i18n$tcf.filterNoVendors;
  var useVendors = useMemo(function () {
    return Object.values(gvlVendors) // Only show vendor if suitable for the current filter
    .filter(function (_ref) {
      var usedPurposes = _ref[tcfFilterBy === "consent" ? "purposes" : "legIntPurposes"];
      return tcfFilterBy === "consent" ? true : usedPurposes.length > 0;
    }) // We need to manually sort it again cause the `vendor-list.json#vendors` property uses numeric keys and
    // JavaScript does automatically sort it
    .sort(function (_ref2, _ref3) {
      var x = _ref2.name;
      var y = _ref3.name;
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }, [gvlVendors, tcfFilterBy, model]);
  var hasVendors = useVendors.length > 0;
  return h("div", bannerGroupsInlineStyleGroup(banner), h(BannerGroupSectionTitle, {
    title: vendors
  }, useVendors.map(function (_ref4) {
    var id = _ref4.id;
    return h(BannerTcfVendor, {
      key: id,
      id: id
    });
  }), !hasVendors && filterNoVendors));
};

export { BannerTcfVendorList };