import { findUsedDeclarations } from "@devowl-wp/cookie-consent-web-client";
import { useBanner } from "../..";
import { useMemo } from "react";
/**
 * Get a list of used declarations for e.g. `purposes`. It can also return an
 * empty array if the given declaration does not have any items.
 */

function useBannerTcfDeclarations(declaration) {
  var banner = useBanner();
  var _banner$tcf = banner.tcf,
      gvl = _banner$tcf.gvl,
      model = _banner$tcf.model,
      tcfFilterBy = banner.tcfFilterBy;
  var declarations = gvl[declaration];
  return useMemo(function () {
    return findUsedDeclarations(declaration, gvl, model, tcfFilterBy);
  }, [declarations, gvl, model, declaration, tcfFilterBy]);
}

export { useBannerTcfDeclarations };