import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import { isPurposeEnabledForVendor } from ".";

/**
 * Get the function used to get vendors for a specific declaration.
 *
 * @see https://www.npmjs.com/package/@iabtcf/core#get-only-vendors-with-a-specific-feature-or-purpose-under-legal-basis
 */
function getGetterForGivenDeclaration(declaration, isLegInt) {
  switch (declaration) {
    case "features":
      return "getVendorsWithFeature";

    case "specialFeatures":
      return "getVendorsWithSpecialFeature";

    case "specialPurposes":
      return "getVendorsWithSpecialPurpose";

    case "purposes":
      return isLegInt ? "getVendorsWithLegIntPurpose" : "getVendorsWithConsentPurpose";

    default:
      // This can never happen due to strictly typed parameters, but safely fall back
      return "getVendorsWithConsentPurpose";
  }
}
/**
 * Read vendors for a given declaration and also respect the publisher restrictions of a given model.
 */


function findUsedVendorsForDeclaration(gvl, model, id) {
  for (var _len = arguments.length, _ref = new Array(_len > 3 ? _len - 3 : 0), _key = 3; _key < _len; _key++) {
    _ref[_key - 3] = arguments[_key];
  }

  var declaration = _ref[0],
      isLegInt = _ref[1];
  var getter = getGetterForGivenDeclaration(declaration, isLegInt);
  var vendors = gvl[getter](id);

  if (["getVendorsWithLegIntPurpose", "getVendorsWithConsentPurpose"].indexOf(getter) > -1) {
    vendors = _objectSpread(_objectSpread({}, vendors), gvl.getVendorsWithFlexiblePurpose(id));
  }

  return Object.values(vendors).filter(function (vendor) {
    if (declaration === "purposes") {
      return isPurposeEnabledForVendor(model, id, isLegInt, vendor);
    }

    return true;
  }) // We need to manually sort it again cause the `vendor-list.json#vendors` property uses numeric keys and
  // JavaScript does automatically sort it
  .sort(function (_ref2, _ref3) {
    var x = _ref2.name;
    var y = _ref3.name;
    return x < y ? -1 : x > y ? 1 : 0;
  });
}

export { findUsedVendorsForDeclaration };