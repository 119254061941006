import { scaleMobile } from "../../../..";

function bannerInlineStyleTcfStacksTitle(_ref) {
  var _ref$bodyDesign = _ref.bodyDesign,
      tcfStacksTitleFontSize = _ref$bodyDesign.tcfStacksTitleFontSize,
      tcfStacksTitleFontColor = _ref$bodyDesign.tcfStacksTitleFontColor,
      tcfStacksTitleFontWeight = _ref$bodyDesign.tcfStacksTitleFontWeight,
      antiAdBlocker = _ref.customCss.antiAdBlocker,
      mobile = _ref.mobile,
      pageRequestUuid4 = _ref.pageRequestUuid4;
  var style = {
    fontSize: scaleMobile(mobile, pageRequestUuid4, +tcfStacksTitleFontSize),
    color: tcfStacksTitleFontColor,
    fontWeight: tcfStacksTitleFontWeight
  };
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-tcf-stacks-title",
    style: style
  };
}

export { bannerInlineStyleTcfStacksTitle };