import { useMemo } from "react";
import { BannerTcfStack } from ".";
import { findUsedDeclarations } from "@devowl-wp/cookie-consent-web-client";
import { useBanner, bannerInlineStyleTcfStacksContainer } from "../../../..";

var BannerTcfStackList = function BannerTcfStackList() {
  var banner = useBanner();
  var _banner$tcf = banner.tcf,
      gvl = _banner$tcf.gvl,
      model = _banner$tcf.model,
      _banner$texts = banner.texts,
      tcfStacksCustomName = _banner$texts.tcfStacksCustomName,
      tcfStacksCustomDescription = _banner$texts.tcfStacksCustomDescription,
      listServicesNotice = banner.listServicesNotice;
  var vendors = gvl.vendors,
      stacks = gvl.stacks,
      purpose1 = gvl.purposes[1]; // Always show Purpose #1 in initial layer, but first check if it is generally in use

  var usesPurpose1 = useMemo(function () {
    var legInt = findUsedDeclarations("purposes", gvl, model, "legInt").filter(function (_ref) {
      var id = _ref.id;
      return id === 1;
    });
    var consent = findUsedDeclarations("purposes", gvl, model, "consent").filter(function (_ref2) {
      var id = _ref2.id;
      return id === 1;
    });
    return legInt.length > 0 || consent.length > 0;
  }, [vendors, gvl, model]);
  return h("div", bannerInlineStyleTcfStacksContainer(banner), usesPurpose1 && h(BannerTcfStack, {
    item: {
      id: undefined,
      description: purpose1.description,
      name: purpose1.name,
      purposes: [],
      specialFeatures: []
    }
  }), Object.values(stacks).map(function (stack) {
    return h(BannerTcfStack, {
      key: stack.id,
      item: stack
    });
  }), !listServicesNotice && h(BannerTcfStack, {
    item: {
      id: undefined,
      description: tcfStacksCustomDescription,
      name: tcfStacksCustomName,
      purposes: [],
      specialFeatures: []
    },
    isLast: true
  }));
};

export { BannerTcfStackList };