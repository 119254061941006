/**
 * See `transformTcfAdTags` for more information.
 */
function transformTcfSrc(oldSrc, tcfStringForVendors, gvl) {
  var src = oldSrc.replace(/(gdpr=)(\${GDPR})/gm, "$1".concat(tcfStringForVendors ? "1" : "0"));

  if (tcfStringForVendors) {
    // We do have a TCF string, cause the GDPR applies here
    src = src.replace(/(gdpr_consent=)(\${GDPR_CONSENT_(\d+)})/gm, function (m, parameter, previousValue, vendor) {
      // Get vendor from GVL
      var gvlVendor = gvl.vendors[vendor];
      return "".concat(parameter).concat(gvlVendor ? tcfStringForVendors : previousValue);
    });
  }

  return src;
}

export { transformTcfSrc };