import _extends from "@babel/runtime/helpers/extends";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _toPropertyKey from "@babel/runtime/helpers/toPropertyKey";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useMemo, Fragment, useLayoutEffect, useState, useCallback } from "react"; // eslint-disable-next-line import/no-extraneous-dependencies

import { CheckOutlined } from "@ant-design/icons-svg";
import { isPurposeEnabledForVendor } from "@devowl-wp/cookie-consent-web-client";
import { useBanner, commonInlineStyleGroupsCookie, commonGroupsInlineStyleLinkMore, commonInlineStyleGroupsCheckbox, CookieProperty, PlainAntdIconAsSvg } from "../../../..";

var BannerTcfVendor = function BannerTcfVendor(_ref) {
  var _deviceStorageDisclos;

  var id = _ref.id;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isRendered = _useState2[0],
      setIsRendered = _useState2[1];

  var _useState3 = useState(false),
      _useState4 = _slicedToArray(_useState3, 2),
      isHover = _useState4[0],
      setIsHover = _useState4[1];

  var _useState5 = useState(false),
      _useState6 = _slicedToArray(_useState5, 2),
      isOpen = _useState6[0],
      setIsOpen = _useState6[1];

  var banner = useBanner();
  var tcfFilterBy = banner.tcfFilterBy,
      _banner$tcf = banner.tcf,
      gvl = _banner$tcf.gvl,
      model = _banner$tcf.model,
      vendorConfigurations = _banner$tcf.original.vendorConfigurations;
  var vendor = gvl.vendors[id],
      purposes = gvl.purposes,
      specialPurposes = gvl.specialPurposes,
      features = gvl.features,
      specialFeatures = gvl.specialFeatures;

  var _ref2 = tcfFilterBy === "consent" ? "purposes" : "legIntPurposes",
      name = vendor.name,
      policyUrl = vendor.policyUrl,
      usedPurposes = vendor[_ref2],
      flexiblePurposes = vendor.flexiblePurposes,
      usedSpecialPurposes = vendor.specialPurposes,
      usedFeatures = vendor.features,
      usedSpecialFeatures = vendor.specialFeatures,
      usesCookies = vendor.usesCookies,
      cookieMaxAgeSeconds = vendor.cookieMaxAgeSeconds,
      cookieRefresh = vendor.cookieRefresh,
      usesNonCookieAccess = vendor.usesNonCookieAccess,
      restVendor = _objectWithoutProperties(vendor, ["name", "policyUrl", _ref2, "flexiblePurposes", "specialPurposes", "features", "specialFeatures", "usesCookies", "cookieMaxAgeSeconds", "cookieRefresh", "usesNonCookieAccess"].map(_toPropertyKey));

  var _useMemo = useMemo(function () {
    return Object.values(vendorConfigurations).filter(function (_ref3) {
      var vendorId = _ref3.vendorId;
      return vendorId === id;
    })[0];
  }, [id]),
      ePrivacyUSA = _useMemo.ePrivacyUSA;

  var filteredPurposes = useMemo(function () {
    return [].concat(_toConsumableArray(usedPurposes), _toConsumableArray(flexiblePurposes.filter(function (flexiblePurposeId) {
      return usedPurposes.indexOf(flexiblePurposeId) === -1;
    }))).filter(function (purposeId) {
      return isPurposeEnabledForVendor(model, purposeId, tcfFilterBy === "legInt", vendor);
    });
  }, [id, tcfFilterBy]);

  var descriptionFontSize = banner.group.descriptionFontSize,
      _banner$i18n = banner.i18n,
      _banner$i18n$tcf = _banner$i18n.tcf,
      i18nTcfDeclarations = _banner$i18n$tcf.declarations,
      i18nTcf = _objectWithoutProperties(_banner$i18n$tcf, ["declarations"]),
      i18n = _objectWithoutProperties(_banner$i18n, ["tcf"]),
      activeAction = banner.activeAction; // Get device storage disclosure (need to type to any cause the original GVL does not provide this information)


  var deviceStorageDisclosure = restVendor.deviceStorageDisclosure;
  useLayoutEffect(function () {
    setIsRendered(true);
  }, []);
  var checkedList = model[tcfFilterBy === "consent" ? "vendorConsents" : "vendorLegitimateInterests"];
  var isDisabled = activeAction === "history"; // Save checked in own state cause the model does not trigger a re-render

  var modelIsChecked = checkedList.has(id);

  var _useState7 = useState(modelIsChecked),
      _useState8 = _slicedToArray(_useState7, 2),
      isChecked = _useState8[0],
      setIsChecked = _useState8[1]; // Update our checked state depending on the original value (e.g. switch of legInt | consent filter)


  useEffect(function () {
    setIsChecked(modelIsChecked);
  }, [modelIsChecked]);
  var handleCheckbox = useCallback(function () {
    if (!isDisabled) {
      var newState = !isChecked;

      try {
        checkedList[newState ? "set" : "unset"](id);
        setIsChecked(newState);
      } catch (e) {// Silence is golden.
      }
    }
  }, [isDisabled, id, isChecked, checkedList, setIsChecked]);
  return h("div", commonInlineStyleGroupsCookie(banner), h("div", {
    style: {
      marginBottom: 10
    }
  }, h(PlainAntdIconAsSvg, _extends({
    icon: CheckOutlined,
    onClick: handleCheckbox
  }, commonInlineStyleGroupsCheckbox(banner, isRendered, isChecked, isDisabled, descriptionFontSize))), h("span", {
    style: {
      verticalAlign: "middle",
      cursor: "pointer"
    }
  }, h("strong", {
    onClick: handleCheckbox
  }, name), h(Fragment, null, "\xA0\xA0\u2022\xA0\xA0", h("a", _extends({
    href: "#"
  }, commonGroupsInlineStyleLinkMore(banner, isHover), {
    onClick: function onClick(e) {
      setIsOpen(!isOpen);
      e.preventDefault();
    },
    onMouseEnter: function onMouseEnter() {
      return setIsHover(true);
    },
    onMouseLeave: function onMouseLeave() {
      return setIsHover(false);
    }
  }), isOpen ? i18nTcf.hideMore : i18nTcf.showMore)))), isOpen && h(Fragment, null, h(CookieProperty, {
    label: i18n.providerPrivacyPolicy,
    value: policyUrl
  }), h(CookieProperty, {
    label: i18n.ePrivacyUSA,
    value: ePrivacyUSA === 2 ? i18n.unknown : ePrivacyUSA === 1 ? i18n.yes : i18n.no
  }), filteredPurposes.length > 0 && h(CookieProperty, {
    label: i18nTcfDeclarations.purposes.title,
    value: filteredPurposes.map(function (declarationId) {
      return purposes[declarationId].name;
    }).join(", ")
  }), usedSpecialPurposes.length > 0 && h(CookieProperty, {
    label: i18nTcfDeclarations.specialPurposes.title,
    value: usedSpecialPurposes.map(function (declarationId) {
      return specialPurposes[declarationId].name;
    }).join(", ")
  }), usedFeatures.length > 0 && h(CookieProperty, {
    label: i18nTcfDeclarations.features.title,
    value: usedFeatures.map(function (declarationId) {
      return features[declarationId].name;
    }).join(", ")
  }), usedSpecialFeatures.length > 0 && h(CookieProperty, {
    label: i18nTcfDeclarations.specialFeatures.title,
    value: usedSpecialFeatures.map(function (declarationId) {
      return specialFeatures[declarationId].name;
    }).join(", ")
  }), h(CookieProperty, {
    label: i18n.usesCookies,
    value: usesCookies,
    printValueAs: "boolean"
  }), usesCookies && h(CookieProperty, {
    label: i18n.duration,
    value: cookieMaxAgeSeconds <= 0 ? "Session" : "".concat(cookieMaxAgeSeconds, " ").concat(i18n.durationUnit["s"])
  }), h(CookieProperty, {
    label: i18n.cookieRefresh,
    value: cookieRefresh,
    printValueAs: "boolean"
  }), h(CookieProperty, {
    label: i18n.usesNonCookieAccess,
    value: usesNonCookieAccess,
    printValueAs: "boolean"
  }), deviceStorageDisclosure === null || deviceStorageDisclosure === void 0 ? void 0 : (_deviceStorageDisclos = deviceStorageDisclosure.disclosures) === null || _deviceStorageDisclos === void 0 ? void 0 : _deviceStorageDisclos.map(function (_ref4) {
    var type = _ref4.type,
        identifier = _ref4.identifier,
        domain = _ref4.domain,
        maxAgeSeconds = _ref4.maxAgeSeconds,
        cookieRefresh = _ref4.cookieRefresh,
        disclosurePurposes = _ref4.purposes;
    return h(CookieProperty, {
      key: name,
      label: i18n.technicalCookieDefinition,
      value: h("span", {
        style: {
          fontFamily: "monospace"
        }
      }, identifier)
    }, h(CookieProperty, {
      label: i18n.type,
      value: getTcfCookieTypeLocalization(type)
    }), !!domain && h(CookieProperty, {
      label: i18n.host,
      value: h("span", {
        style: {
          fontFamily: "monospace"
        }
      }, domain)
    }), maxAgeSeconds !== null && h(CookieProperty, {
      label: i18n.duration,
      value: maxAgeSeconds <= 0 ? "Session" : "".concat(maxAgeSeconds, " ").concat(i18n.durationUnit["s"])
    }), h(CookieProperty, {
      label: i18n.cookieRefresh,
      value: cookieRefresh,
      printValueAs: "boolean"
    }), !!(disclosurePurposes !== null && disclosurePurposes !== void 0 && disclosurePurposes.length) && h(CookieProperty, {
      label: i18nTcfDeclarations.purposes.title,
      value: disclosurePurposes.map(function (purposeId) {
        return purposes[purposeId].name;
      }).join(", ")
    }));
  })));
};

function getTcfCookieTypeLocalization(type) {
  switch (type) {
    case "cookie":
      return "HTTP Cookie";

    case "web":
      return "LocalStorage, Session Storage, IndexDB";

    case "app":
      return "App";

    default:
      return type;
  }
}

export { BannerTcfVendor, getTcfCookieTypeLocalization };