import { Fragment } from "react";
import { scaleMobile, useBanner, bannerGroupsInlineStyle, BannerGroupSectionTitle, CommonSwitchButton, BannerTcfVendorList, BannerTcfDeclarationList, BannerGroupList } from "../../..";
var DECLARATIONS = ["purposes", "specialPurposes", "features", "specialFeatures"];

var BannerTcfGroupList = function BannerTcfGroupList() {
  var banner = useBanner();
  var groupSpacing = banner.group.groupSpacing,
      mobile = banner.mobile,
      tcfFilterBy = banner.tcfFilterBy,
      updateTcfFilterBy = banner.updateTcfFilterBy,
      pageRequestUuid4 = banner.pageRequestUuid4,
      _banner$i18n = banner.i18n,
      legitimateInterest = _banner$i18n.legitimateInterest,
      consent = _banner$i18n.consent,
      _banner$i18n$tcf = _banner$i18n.tcf,
      filterText = _banner$i18n$tcf.filterText,
      nonStandard = _banner$i18n$tcf.nonStandard,
      nonStandardDesc = _banner$i18n$tcf.nonStandardDesc,
      tcfStandard = _banner$i18n$tcf.tcfStandard,
      tcfStandardDesc = _banner$i18n$tcf.tcfStandardDesc;
  var groupTitleStyle = {
    margin: "20px 0 10px 0"
  };
  var groupDividerStyle = {
    height: scaleMobile(mobile, pageRequestUuid4, +groupSpacing)
  };
  return h(Fragment, null, h("div", {
    style: groupTitleStyle
  }, h(BannerGroupSectionTitle, {
    title: nonStandard
  }, nonStandardDesc)), h(BannerGroupList, null), h("div", {
    style: groupTitleStyle
  }, h(BannerGroupSectionTitle, {
    title: tcfStandard
  }, tcfStandardDesc, h("div", {
    style: groupTitleStyle
  }, filterText, "\xA0", h(CommonSwitchButton, {
    onClick: function onClick() {
      return updateTcfFilterBy("legInt");
    },
    active: tcfFilterBy === "legInt"
  }, legitimateInterest), h(CommonSwitchButton, {
    onClick: function onClick() {
      return updateTcfFilterBy("consent");
    },
    active: tcfFilterBy === "consent"
  }, consent)))), h("div", bannerGroupsInlineStyle(banner), h(BannerTcfVendorList, null), h("div", {
    style: groupDividerStyle
  }), DECLARATIONS.map(function (declaration, index) {
    return h(Fragment, {
      key: declaration
    }, h(BannerTcfDeclarationList, {
      key: declaration,
      declaration: declaration
    }), index !== DECLARATIONS.length - 1 && h("div", {
      style: groupDividerStyle
    }));
  })));
};

export { BannerTcfGroupList };