import { scaleMobile } from "../../../..";

function bannerInlineStyleTcfStacksDescription(_ref) {
  var _ref$bodyDesign = _ref.bodyDesign,
      tcfStacksDescriptionFontSize = _ref$bodyDesign.tcfStacksDescriptionFontSize,
      tcfStacksDescriptionFontColor = _ref$bodyDesign.tcfStacksDescriptionFontColor,
      tcfStacksDescriptionFontWeight = _ref$bodyDesign.tcfStacksDescriptionFontWeight,
      tcfStacksDescriptionMargin = _ref$bodyDesign.tcfStacksDescriptionMargin,
      antiAdBlocker = _ref.customCss.antiAdBlocker,
      mobile = _ref.mobile,
      pageRequestUuid4 = _ref.pageRequestUuid4;
  var style = {
    fontSize: scaleMobile(mobile, pageRequestUuid4, +tcfStacksDescriptionFontSize),
    color: tcfStacksDescriptionFontColor,
    fontWeight: tcfStacksDescriptionFontWeight,
    margin: scaleMobile(mobile, pageRequestUuid4, tcfStacksDescriptionMargin).map(function (i) {
      return "".concat(i, "px");
    }).join(" ")
  };
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-tcf-stacks-title",
    style: style
  };
}

export { bannerInlineStyleTcfStacksDescription };