import { scaleMobile } from "../../../..";

function bannerInlineStyleTcfStacksContainer(_ref) {
  var tcfStacksMargin = _ref.bodyDesign.tcfStacksMargin,
      antiAdBlocker = _ref.customCss.antiAdBlocker,
      mobile = _ref.mobile,
      pageRequestUuid4 = _ref.pageRequestUuid4;
  var style = {
    margin: scaleMobile(mobile, pageRequestUuid4, tcfStacksMargin).map(function (i) {
      return "".concat(i, "px");
    }).join(" "),
    textAlign: "left",
    lineHeight: 1.5
  };
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-tcf-stacks-container",
    style: style
  };
}

export { bannerInlineStyleTcfStacksContainer };