import { findUsedVendorsForDeclaration } from ".";
/**
 * Get a list of used declarations for e.g. `purposes`. It can also return an
 * empty array if the given declaration does not have any items/vendors.
 */

function findUsedDeclarations(declaration, gvl, model, filterBy) {
  var declarations = gvl[declaration];
  var declarationValues = Object.values(declarations); // Collect a map of `id` => `length of vendors` so we can hide the complete declaration if no vendor is available

  var lengthMap = declarationValues.reduce(function (map, _ref) {
    var id = _ref.id;
    map[id] = findUsedVendorsForDeclaration(gvl, model, id, declaration, filterBy === "legInt").length;
    return map;
  }, {});
  return declarationValues.filter(function (_ref2) {
    var id = _ref2.id;
    return lengthMap[id];
  });
}

export { findUsedDeclarations };