import { scaleMobile } from "../../../..";

function bannerInlineStyleTcfStacksAccordion(_ref, isLast, isActive, isHover) {
  var _ref$bodyDesign = _ref.bodyDesign,
      tcfStacksBorderWidth = _ref$bodyDesign.tcfStacksBorderWidth,
      tcfStacksBorderColor = _ref$bodyDesign.tcfStacksBorderColor,
      tcfStacksPadding = _ref$bodyDesign.tcfStacksPadding,
      tcfStacksBg = _ref$bodyDesign.tcfStacksBg,
      tcfStacksHoverBg = _ref$bodyDesign.tcfStacksHoverBg,
      tcfStacksActiveBg = _ref$bodyDesign.tcfStacksActiveBg,
      antiAdBlocker = _ref.customCss.antiAdBlocker,
      mobile = _ref.mobile,
      pageRequestUuid4 = _ref.pageRequestUuid4;
  var border = "".concat(scaleMobile(mobile, pageRequestUuid4, tcfStacksBorderWidth), "px solid ").concat(tcfStacksBorderColor);
  var style = {
    padding: scaleMobile(mobile, pageRequestUuid4, tcfStacksPadding).map(function (i) {
      return "".concat(i, "px");
    }).join(" "),
    borderTop: border,
    borderBottom: isLast ? border : undefined,
    cursor: "pointer",
    background: isHover ? tcfStacksHoverBg : isActive ? tcfStacksActiveBg : tcfStacksBg
  };
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-tcf-stacks-accordion",
    style: style
  };
}

export { bannerInlineStyleTcfStacksAccordion };