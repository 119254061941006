import { RestrictionType } from "@iabtcf/core";

function isPurposeEnabledForVendor(model, id, isLegInt, _ref) {
  var _publisherRestriction;

  var vendorId = _ref.id,
      legIntPurposes = _ref.legIntPurposes;
  var publisherRestrictions = model.publisherRestrictions.getRestrictions(vendorId);
  var disabledPurposes = publisherRestrictions.map(function (_ref2) {
    var purposeId = _ref2.purposeId,
        restrictionType = _ref2.restrictionType;
    return purposeId === id && restrictionType === RestrictionType.NOT_ALLOWED && purposeId;
  }).filter(Boolean);

  if (disabledPurposes.indexOf(id) > -1) {
    return false;
  }

  var legIntRestriction = (_publisherRestriction = publisherRestrictions.filter(function (_ref3) {
    var purposeId = _ref3.purposeId,
        restrictionType = _ref3.restrictionType;
    return purposeId === id && restrictionType !== RestrictionType.NOT_ALLOWED;
  })[0]) === null || _publisherRestriction === void 0 ? void 0 : _publisherRestriction.restrictionType; // Fallback to default setting

  if (!legIntRestriction) {
    legIntRestriction = legIntPurposes.indexOf(id) > -1 ? RestrictionType.REQUIRE_LI : RestrictionType.REQUIRE_CONSENT;
  }

  if (isLegInt && legIntRestriction === RestrictionType.REQUIRE_CONSENT) {
    return false;
  } else if (!isLegInt && legIntRestriction === RestrictionType.REQUIRE_LI) {
    return false;
  }

  return true;
}

export { isPurposeEnabledForVendor };