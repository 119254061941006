import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import { useState } from "react";
import { useBannerOrBlocker, commonInlineStyleSwitchButton } from "../..";

var CommonSwitchButton = function CommonSwitchButton(_ref) {
  var children = _ref.children,
      active = _ref.active,
      rest = _objectWithoutProperties(_ref, ["children", "active"]);

  var bannerOrBlocker = useBannerOrBlocker();

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isHover = _useState2[0],
      setIsHover = _useState2[1];

  return h("a", _extends({}, rest, {
    onMouseEnter: function onMouseEnter() {
      return setIsHover(true);
    },
    onMouseLeave: function onMouseLeave() {
      return setIsHover(false);
    }
  }, commonInlineStyleSwitchButton(bannerOrBlocker, active, isHover)), children);
};

export { CommonSwitchButton };