import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { Fragment, useMemo, useEffect, useLayoutEffect, useState, useCallback } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { CheckOutlined } from "@ant-design/icons-svg";
import { findUsedVendorsForDeclaration } from "@devowl-wp/cookie-consent-web-client";
import { useBanner, commonInlineStyleGroupsCookie, commonGroupsInlineStyleLinkMore, commonInlineStyleGroupsCheckbox, CookieProperty, PlainAntdIconAsSvg, reactNl2Br, jsxJoin } from "../../../..";

var BannerTcfDeclaration = function BannerTcfDeclaration(_ref) {
  var declaration = _ref.declaration,
      id = _ref.id;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isRendered = _useState2[0],
      setIsRendered = _useState2[1];

  var _useState3 = useState(false),
      _useState4 = _slicedToArray(_useState3, 2),
      isHover = _useState4[0],
      setIsHover = _useState4[1];

  var _useState5 = useState(false),
      _useState6 = _slicedToArray(_useState5, 2),
      isOpen = _useState6[0],
      setIsOpen = _useState6[1];

  var banner = useBanner();
  var _banner$tcf = banner.tcf,
      gvl = _banner$tcf.gvl,
      model = _banner$tcf.model,
      tcfFilterBy = banner.tcfFilterBy;
  var _gvl$declaration$id$t = gvl[declaration][id.toString()],
      name = _gvl$declaration$id$t.name,
      descriptionLegal = _gvl$declaration$id$t.descriptionLegal;
  var descriptionFontSize = banner.group.descriptionFontSize,
      activeAction = banner.activeAction,
      _banner$i18n = banner.i18n,
      purpose = _banner$i18n.purpose,
      _banner$i18n$tcf = _banner$i18n.tcf,
      showMore = _banner$i18n$tcf.showMore,
      hideMore = _banner$i18n$tcf.hideMore,
      vendors = _banner$i18n$tcf.vendors;
  useLayoutEffect(function () {
    setIsRendered(true);
  }, []);
  var isEssentialDeclaration = ["specialPurposes", "features"].indexOf(declaration) > -1;
  var isDisabled = activeAction === "history" || isEssentialDeclaration;
  var checkedList = isEssentialDeclaration ? undefined : model[declaration === "specialFeatures" ? "specialFeatureOptins" : tcfFilterBy === "legInt" ? "purposeLegitimateInterests" : "purposeConsents"]; // Save checked in own state cause the model does not trigger a re-render

  var modelIsChecked = isEssentialDeclaration || !!(checkedList !== null && checkedList !== void 0 && checkedList.has(id));

  var _useState7 = useState(modelIsChecked),
      _useState8 = _slicedToArray(_useState7, 2),
      isChecked = _useState8[0],
      setIsChecked = _useState8[1]; // Update our checked state depending on the original value (e.g. switch of legInt | consent filter)


  useEffect(function () {
    setIsChecked(modelIsChecked);
  }, [modelIsChecked]);
  var handleCheckbox = useCallback(function () {
    if (!isDisabled) {
      var newState = !isChecked;

      try {
        checkedList[newState ? "set" : "unset"](id);
        setIsChecked(newState);
      } catch (e) {// Silence is golden.
      }
    }
  }, [isDisabled, id, isChecked, checkedList, setIsChecked]); // Calculate all vendors using this declaration

  var useVendors = useMemo(function () {
    return findUsedVendorsForDeclaration(gvl, model, id, declaration, tcfFilterBy === "legInt");
  }, [gvl, model, id, declaration, tcfFilterBy]);
  return h("div", commonInlineStyleGroupsCookie(banner), h("div", {
    style: {
      marginBottom: 10
    }
  }, ["purposes", "specialFeatures"].indexOf(declaration) > -1 && h(PlainAntdIconAsSvg, _extends({
    icon: CheckOutlined,
    onClick: handleCheckbox
  }, commonInlineStyleGroupsCheckbox(banner, isRendered, isChecked, isDisabled, descriptionFontSize))), h("span", {
    style: {
      verticalAlign: "middle",
      cursor: "pointer"
    }
  }, h("strong", {
    onClick: handleCheckbox
  }, name), h(Fragment, null, "\xA0\xA0\u2022\xA0\xA0", h("a", _extends({
    href: "#"
  }, commonGroupsInlineStyleLinkMore(banner, isHover), {
    onClick: function onClick(e) {
      setIsOpen(!isOpen);
      e.preventDefault();
    },
    onMouseEnter: function onMouseEnter() {
      return setIsHover(true);
    },
    onMouseLeave: function onMouseLeave() {
      return setIsHover(false);
    }
  }), isOpen ? hideMore : showMore)))), isOpen && h(Fragment, null, h(CookieProperty, {
    label: purpose,
    value: reactNl2Br(descriptionLegal)
  }), h(CookieProperty, {
    label: vendors,
    value: jsxJoin(useVendors.map(function (_ref2) {
      var id = _ref2.id,
          name = _ref2.name,
          policyUrl = _ref2.policyUrl;
      return h("a", _extends({
        key: id,
        href: policyUrl,
        target: "_blank",
        rel: "noreferrer"
      }, commonGroupsInlineStyleLinkMore(banner, false)), name);
    }), ", ")
  })));
};

export { BannerTcfDeclaration };