import { PurposeRestriction, RestrictionType } from "@iabtcf/core";

/**
 * Apply publisher restrictions to a given `TCModel`.
 *
 * @see https://www.npmjs.com/package/@iabtcf/core#user-content-setting-publisher-restrictions
 */
function applyRestrictivePurposes(model, vendorConfigurations) {
  if (!model.isServiceSpecific) {
    return; // must only be saved to a service-specific TC String
  }

  Object.values(vendorConfigurations).forEach(function (_ref) {
    var vendorId = _ref.vendorId,
        restrictivePurposes = _ref.restrictivePurposes;

    for (var purposeId in restrictivePurposes["normal"]) {
      var _restrictivePurposes$ = restrictivePurposes["normal"][purposeId],
          enabled = _restrictivePurposes$.enabled,
          legInt = _restrictivePurposes$.legInt;
      var purposeRestriction = new PurposeRestriction();
      purposeRestriction.purposeId = +purposeId;

      if (!enabled) {
        purposeRestriction.restrictionType = RestrictionType.NOT_ALLOWED;
      } else if (legInt) {
        purposeRestriction.restrictionType = legInt === "yes" ? RestrictionType.REQUIRE_LI : RestrictionType.REQUIRE_CONSENT;
      }

      if (purposeRestriction.restrictionType !== undefined) {
        model.publisherRestrictions.add(vendorId, purposeRestriction);
      }
    }
  });
}

export { applyRestrictivePurposes };