import { BannerTcfDeclaration } from ".";
import { useBanner, useBannerTcfDeclarations, bannerGroupsInlineStyleGroup, BannerGroupSectionTitle } from "../../../..";

var BannerTcfDeclarationList = function BannerTcfDeclarationList(_ref) {
  var declaration = _ref.declaration;
  var banner = useBanner();
  var _banner$i18n$tcf$decl = banner.i18n.tcf.declarations[declaration],
      title = _banner$i18n$tcf$decl.title,
      desc = _banner$i18n$tcf$decl.desc;
  var declarations = useBannerTcfDeclarations(declaration);
  return declarations.length > 0 ? h("div", bannerGroupsInlineStyleGroup(banner), h(BannerGroupSectionTitle, {
    title: title
  }, desc, declarations.map(function (_ref2) {
    var id = _ref2.id;
    return h(BannerTcfDeclaration, {
      key: id,
      declaration: declaration,
      id: id
    });
  }))) : null;
};

export { BannerTcfDeclarationList };