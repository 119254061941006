import { GVL, TCModel, TCString } from "@iabtcf/core";

/**
 * Restore e.g. a history entry to a valid `GVL` and `TCModel` instance.
 * Returns `undefined` if no TCF vendor-list.json object is given.
 */
function createTcfModel(_ref) {
  var tcf = _ref.tcf,
      tcfMeta = _ref.tcfMeta,
      tcfString = _ref.tcfString;

  if (!tcf || !tcfMeta || !Object.keys(tcf.vendors).length) {
    return undefined;
  }

  var gvl = new GVL(Object.assign({}, tcf, tcfMeta)); // See https://git.io/Jmjqs

  gvl.lang_ = tcfMeta.language;
  var model = new TCModel(gvl);
  var publisherCc = tcfMeta.publisherCc;

  if (publisherCc) {
    model.publisherCountryCode = publisherCc;
  }

  if (tcfString) {
    TCString.decode(tcfString, model);
  } else {
    model.unsetAll();
  }

  return {
    gvl: gvl,
    model: model,
    original: tcf,
    metadata: tcfMeta
  };
}

export { createTcfModel };