import _createForOfIteratorHelper from "@babel/runtime/helpers/createForOfIteratorHelper";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { transformTcfSrc } from ".";
/**
 * Quote:
 *
 * > When a creative is rendered, it may contain a number of pixels under `<img>` tags. For example,
 * > `<img src="http://vendor-a.com/key1=val1&key2=val2">` which fires an HTTP GET request from the browser to Vendor A’s domain.
 *
 * So, it automatically transforms all links to GDPR compliant URLs.
 *
 * @see https://git.io/JtmZn
 * @see https://git.io/JtmZO
 */

function transformTcfAdTags(tcfStringForVendors, gvl) {
  var nodes = [].concat(_toConsumableArray(document.querySelectorAll('[src*="gdpr=${GDPR"]')), _toConsumableArray(document.querySelectorAll('[src*="gdpr_consent=${GDPR"]'))) // Unique nodes
  .filter(function (value, index, self) {
    return self.indexOf(value) === index;
  });

  var _iterator = _createForOfIteratorHelper(nodes),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var node = _step.value;
      node.setAttribute("src", transformTcfSrc(node.getAttribute("src"), tcfStringForVendors, gvl));
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
}

export { transformTcfAdTags };