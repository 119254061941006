import { scaleMobile } from "..";

function commonInlineStyleSwitchButton(_ref, active, isHover) {
  var _ref$bodyDesign = _ref.bodyDesign,
      acceptAllBg = _ref$bodyDesign.acceptAllBg,
      acceptAllFontColor = _ref$bodyDesign.acceptAllFontColor,
      acceptAllBorderWidth = _ref$bodyDesign.acceptAllBorderWidth,
      acceptAllBorderColor = _ref$bodyDesign.acceptAllBorderColor,
      borderRadius = _ref.layout.borderRadius,
      linkTextDecoration = _ref.design.linkTextDecoration,
      linkColor = _ref.group.linkColor,
      antiAdBlocker = _ref.customCss.antiAdBlocker,
      mobile = _ref.mobile,
      pageRequestUuid4 = _ref.pageRequestUuid4;
  var style = {
    padding: "5px 10px",
    color: active ? acceptAllFontColor : linkColor,
    border: "".concat(pageRequestUuid4 ? scaleMobile(mobile, pageRequestUuid4, acceptAllBorderWidth) : acceptAllBorderWidth, "px solid ").concat(active ? acceptAllBorderColor : "transparent"),
    background: active ? acceptAllBg : undefined,
    borderRadius: +borderRadius,
    textDecoration: active ? undefined : linkTextDecoration,
    cursor: active ? "initial" : "pointer",
    opacity: isHover || active ? 1 : 0.8
  };
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-tcf-groups-switch-button",
    style: style
  };
}

export { commonInlineStyleSwitchButton };