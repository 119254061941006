import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState } from "react"; // eslint-disable-next-line import/no-extraneous-dependencies

import { RightOutlined, DownOutlined, CaretRightFilled, CaretDownFilled } from "@ant-design/icons-svg";
import { useBanner, PlainAntdIconAsSvg, bannerInlineStyleTcfStacksAccordion, bannerInlineStyleTcfStacksAccordionArrow, bannerInlineStyleTcfStacksDescription, bannerInlineStyleTcfStacksTitle } from "../../../../..";

var BannerTcfStack = function BannerTcfStack(_ref) {
  var _ref$item = _ref.item,
      name = _ref$item.name,
      description = _ref$item.description,
      isLast = _ref.isLast;
  var banner = useBanner();
  var tcfStacksArrowType = banner.bodyDesign.tcfStacksArrowType;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      expanded = _useState2[0],
      setExpanded = _useState2[1];

  var _useState3 = useState(false),
      _useState4 = _slicedToArray(_useState3, 2),
      hover = _useState4[0],
      setHover = _useState4[1];

  return h("div", _extends({}, bannerInlineStyleTcfStacksAccordion(banner, isLast, expanded, hover), {
    onClick: function onClick() {
      return setExpanded(!expanded);
    },
    onMouseEnter: function onMouseEnter() {
      return setHover(true);
    },
    onMouseLeave: function onMouseLeave() {
      return setHover(false);
    }
  }), h("div", {
    style: {
      display: "flex",
      alignItems: "center"
    }
  }, tcfStacksArrowType !== "none" && h(PlainAntdIconAsSvg, _extends({
    icon: expanded ? tcfStacksArrowType === "filled" ? CaretDownFilled : DownOutlined : tcfStacksArrowType === "filled" ? CaretRightFilled : RightOutlined
  }, bannerInlineStyleTcfStacksAccordionArrow(banner))), h("div", bannerInlineStyleTcfStacksTitle(banner), name)), expanded && h("div", bannerInlineStyleTcfStacksDescription(banner), description));
};

export { BannerTcfStack };